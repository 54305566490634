<template>
    <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 1090">
        <div id="toastNotice" class="toast" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="toast-body">已複製到剪貼簿</div>
        </div>
    </div>

    <div class="house-page">
        <nav class="d-none d-lg-block py-2 mb-4" aria-label="breadcrumb">
            <div class="container-lg ">
<!--                <ol class="breadcrumb mb-0">-->
<!--                    <li class="breadcrumb-item">-->
<!--                        <router-link to="/">首頁</router-link>-->
<!--                    </li>-->
<!--                    <li class="breadcrumb-item active" aria-current="page">-->
<!--                        <router-link to="/collection">買屋</router-link>-->
<!--                    </li>-->
<!--                </ol>-->
            </div>
        </nav>
        <section class="house-container">
            <div class="container-lg d-flex d-md-block flex-column">
                <div class="house-title order-2 d-flex d-md-none d-lg-flex pt-3 pt-lg-0">
                    <div>
                        <h1>{{ house.subject }}</h1>
                        <p class="mb-0">{{ house.address }}</p>
                    </div>
                    <p class="share d-none d-lg-flex align-items-center"><a href="#0" data-bs-toggle="modal"
                                                                            data-bs-target="#sharetoModal"
                                                                            class="share_modal">
                        <IconShare class="icon"/>
                        分享</a></p>
                </div>

                <div id="coverAnchor" class="cover-wrapper row g-0 g-lg-2 order-1">
                    <a class="cover-share d-flex d-lg-none align-items-center" href="#0" data-bs-toggle="modal"
                       data-bs-target="#sharetoModal">
                        <IconShare class="icon me-1"/>
                        分享</a>
                    <div class="control-panel">
                        <a class="btn btn-option" href="#0" data-bs-toggle="modal" data-bs-target="#galleryModal"
                           data-type="single"
                           :data-src='house.patternImg' v-on:click="galleryModal" v-if="house.patternImg != ''">
                            <i class="icon d-none d-md-inline-block">
                                <IconStructure/>
                            </i>
                            格局圖
                        </a>
                        <a class="btn btn-option" :href="house.youtubeLink" data-bs-toggle="modal"
                           data-bs-target="#galleryModal" data-type="video" v-on:click="galleryModal"
                           v-if="house.youtubeLink != ''">
                            <i class="icon d-none d-md-inline-block">
                                <IconPlay/>
                            </i>
                            影片
                        </a>
                        <a href="javascript:;" class="btn btn-option" data-type="panoramic" @click="galleryModal" v-if="house.vrLink != ''">
                            <i class="icon d-none d-md-inline-block">
                                <IconEye/>
                            </i>
                            全景
                        </a>
                        <a class="btn btn-option btn-option2" href="javascript:;" data-bs-toggle="modal"
                           data-bs-target="#galleryModal" data-type="gallery"
                           v-on:click="galleryModal" v-if="house.imgList.length > 0">
                            全部
                            <span class="d-none d-md-inline-block">照片</span>
                            ({{ house.imgList.length }})
                        </a>
                    </div>
                    <div class="col-lg-6" v-if="house.imgList.length > 0 && modalTypeId != 2">
                        <div class="img-ratio-cover cover-item cov1">
                            <a href="#!" data-bs-toggle="modal" data-bs-target="#galleryModal" data-type="single"
                               :data-src="house.imgList[0]" v-on:click="galleryModal">
                                <img :src="house.imgList[0]" :alt="house.subject + '-' + siteName + '-1'" class="w-100">
                            </a>
                        </div>
                    </div>
                    <div class="col-lg-6" v-if="house.imgList.length == 0 && modalTypeId != 2">
                        <div class="img-ratio-cover cover-item cov1">
                            <img :src="house.defaultImage" alt="" class="w-100">
                            <!--                            <img src="@/assets/images/default.png" alt="" class="w-100">-->
                        </div>
                    </div>
                    <div class="col-lg-6 d-none d-lg-block" v-if="modalTypeId != 2">
                        <div class="row g-2">
                            <div class="col-6" v-for="(img, index) in house.subImg">
                                <div :class="'img-ratio-cover2 cover-item cov' + (index + 2)">
                                    <a href="#!" data-bs-toggle="modal" data-bs-target="#galleryModal"
                                       data-type="single"
                                       :data-src="img"
                                       v-on:click="galleryModal">
                                        <img :src="img" :alt="house.subject + '-' + siteName + '-' + (index + 2)"
                                             class="w-100">
                                    </a>
                                </div>
                            </div>
                            <div class="col-6" v-for="num in (4 - house.subImg.length)">
                                <div :class="'img-ratio-cover2 cover-item cov' + (num + 1)">
                                    <img :src="house.defaultImage" alt="" class="w-100">
                                    <!--                                    <img src="@/assets/images/default.png" alt="" class="w-100">-->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 vr-link" v-if="modalTypeId == 2">
                        <iframe :src="vrLink" title="全景" frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </section>
        <div class="house-info-container">
            <div class="container-lg">
                <div class="row sticky-zone" id="stickyZone">
                    <nav class="sticky-nav" id="stickySpy" :style="(scroll >= infoOffsetTop ? '' : 'display:none;')">
                        <div class="container p-0">
                            <ul class="nav nav-pills">
                                <li class="nav-item">
                                    <a class="nav-link" href="#coverAnchor">照片</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#infoAnchor">基本資料</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#houseAnchor" v-if="house.isLand == 0">房屋介紹</a>
                                    <a class="nav-link" href="#houseAnchor" v-else>土地介紹</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#mapAnchor">生活地圖</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#moreHouse">好房推薦</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                    <div class="col-lg-8 order-2 order-lg-1">
                        <section id="infoAnchor" class="house-info-block">
                            <h3 class="section_info-title d-flex align-items-center">
                                <i class="icon">
                                    <img src="../assets/images/icon-files.svg"
                                         :alt="siteName + '-物件基本資料-' + meta.alt.alt_3_1" class="w-100">
                                </i>
                                基本資料
                            </h3>
                            <div class="detail_content">
                                <ul class="row" v-if="house.isLand == 0">
                                    <li class="col-md-6 item">
                                        <h4 class="item_key">地址</h4>
                                        <p class="item_value">{{ house.address }}</p>
                                    </li>
                                    <li class="col-md-6 item">
                                        <h4 class="item_key">總坪</h4>
                                        <p class="item_value">{{ house.totalPing }}坪</p>
                                    </li>
                                    <li class="col-md-6 item">
                                        <h4 class="item_key">主建坪</h4>
                                        <p class="item_value">{{ house.buildingPing }}坪</p>
                                    </li>
                                    <li class="col-md-6 item" v-if="house.attPing > 0">
                                        <h4 class="item_key">附屬建坪</h4>
                                        <p class="item_value">{{ house.attPing }}坪</p>
                                    </li>
                                    <li class="col-md-6 item" v-if="house.publicPing > 0">
                                        <h4 class="item_key">公共坪</h4>
                                        <p class="item_value">{{ house.publicPing }}坪</p>
                                    </li>
                                    <li class="col-md-6 item">
                                        <h4 class="item_key">土地坪</h4>
                                        <p class="item_value">{{ house.landPing }}坪</p>
                                    </li>
                                    <li class="col-md-6 item" v-if="house.publicPercent > 0">
                                        <h4 class="item_key">公設比</h4>
                                        <p class="item_value">{{ house.publicPercent }}%</p>
                                    </li>
                                    <li class="col-md-6 item" v-if="house.houseUse != ''">
                                        <h4 class="item_key">用途</h4>
                                        <p class="item_value">{{ house.houseUse }}</p>
                                    </li>
                                    <li class="col-md-6 item">
                                        <h4 class="item_key">屋齡</h4>
                                        <p class="item_value">{{ house.houseAge}}</p>
                                    </li>
                                    <li class="col-md-6 item">
                                        <h4 class="item_key">型態</h4>
                                        <p class="item_value">{{ house.houseType }}</p>
                                    </li>
                                    <li class="col-md-6 item" v-if="house.pattern != ''">
                                        <h4 class="item_key">格局</h4>
                                        <p class="item_value">{{ house.pattern }}</p>
                                    </li>
                                    <li class="col-md-6 item" v-if="house.addPattern != ''">
                                        <h4 class="item_key">加蓋格局</h4>
                                        <p class="item_value">{{ house.addPattern }}</p>
                                    </li>
                                    <li class="col-md-6 item">
                                        <h4 class="item_key">樓層</h4>
                                        <p class="item_value">{{ house.floor }}/{{ house.totalFloor }}樓</p>
                                    </li>
                                    <li class="col-md-6 item">
                                        <h4 class="item_key">車位</h4>
                                        <p class="item_value">{{ house.isParking }}</p>
                                    </li>
                                    <!--                                <li class="col-md-6 item" v-if="house.publicPercent > 0">-->
                                    <!--                                    <h4 class="item_key">公設</h4>-->
                                    <!--                                    <p class="item_value">{{ house.publicPercent }}%</p>-->
                                    <!--                                </li>-->
                                </ul>
                                <ul class="row" v-else-if="house.isLand == 1">
                                    <li class="col-md-6 item">
                                        <h4 class="item_key">地址</h4>
                                        <p class="item_value">{{ house.address }}</p>
                                    </li>
                                    <li class="col-md-6 item" v-if="house.sectionNum != ''">
                                        <h4 class="item_key">段號</h4>
                                        <p class="item_value">{{ house.sectionNum }}</p>
                                    </li>
                                    <li class="col-md-6 item">
                                        <h4 class="item_key">土地坪</h4>
                                        <p class="item_value">{{ house.landPing }}坪</p>
                                    </li>
                                    <li class="col-md-6 item">
                                        <h4 class="item_key">型態</h4>
                                        <p class="item_value">{{ house.houseType }}</p>
                                    </li>
                                    <li class="col-md-6 item">
                                        <h4 class="item_key">是否為都市土地</h4>
                                        <p class="item_value">{{ house.cityLand }}</p>
                                    </li>
                                    <li class="col-md-6 item" v-if="house.houseWidth != ''">
                                        <h4 class="item_key">面寬</h4>
                                        <p class="item_value">{{ house.houseWidth }}米</p>
                                    </li>
                                    <li class="col-md-6 item" v-if="house.houseDepth != ''">
                                        <h4 class="item_key">深度</h4>
                                        <p class="item_value">{{ house.houseDepth }}米</p>
                                    </li>
                                    <li class="col-md-6 item" v-if="house.shield !=''">
                                        <h4 class="item_key">建蔽率</h4>
                                        <p class="item_value">{{ house.shield }}%</p>
                                    </li>
                                    <li class="col-md-6 item" v-if="house.volumeRate != ''">
                                        <h4 class="item_key">容積率</h4>
                                        <p class="item_value">{{ house.volumeRate }}%</p>
                                    </li>
                                    <li class="col-md-6 item" v-if="house.roadWidth != ''">
                                        <h4 class="item_key">道路</h4>
                                        <p class="item_value">{{ house.roadWidth }}米</p>
                                    </li>
                                    <li class="col-md-6 item" v-if="house.path != ''">
                                        <h4 class="item_key">面前道路</h4>
                                        <p class="item_value">{{ house.path }}</p>
                                    </li>
                                    <li class="col-md-6 item">
                                        <h4 class="item_key">土地含道路用地</h4>
                                        <p class="item_value">{{ house.roadLand }}</p>
                                    </li>
                                    <li class="col-md-6 item" v-if="house.roadLandPing != ''">
                                        <h4 class="item_key">含道路用地約</h4>
                                        <p class="item_value">{{ house.roadLandPing }}坪</p>
                                    </li>
                                    <li class="col-md-6 item" v-if="house.ownership != ''">
                                        <h4 class="item_key">基地權利</h4>
                                        <p class="item_value">{{ house.ownership }}</p>
                                    </li>
                                    <li class="col-md-6 item" v-if="house.houseUse">
                                        <h4 class="item_key">用途</h4>
                                        <p class="item_value">{{ house.houseUse }}</p>
                                    </li>
                                    <li class="col-md-6 item" v-if="house.situation">
                                        <h4 class="item_key">現況</h4>
                                        <p class="item_value">{{ house.situation }}</p>
                                    </li>
                                    <li class="col-md-6 item" v-if="house.usePartition != ''">
                                        <h4 class="item_key">使用分區</h4>
                                        <p class="item_value">{{ house.usePartition }}</p>
                                    </li>
                                </ul>
                            </div>
                        </section>
                        <section id="carAnchor" class="house-info-block"
                                 v-if="house.isLand == 0 && house.parking == 1 && house.showParking == 1">
                            <h3 class="section_info-title d-flex align-items-center">
                                <i class="icon">
                                    <img src="../assets/images/icon-files.svg"
                                         :alt="siteName + '-車位資料-' + meta.alt.alt_3_1" class="w-100">
                                </i>
                                車位資料
                            </h3>
                            <div class="detail_content">
                                <ul class="row">
                                    <li class="col-md-6 item" v-if="house.parkingInfo.total > 0">
                                        <h4 class="item_key">車位價格</h4>
                                        <p class="item_value">{{ house.parkingInfo.total }}萬</p>
                                    </li>
                                    <li class="col-md-6 item" v-if="house.parkingInfo.ping > 0">
                                        <h4 class="item_key">車位坪</h4>
                                        <p class="item_value">{{ house.parkingInfo.ping }}坪</p>
                                    </li>
                                    <li class="col-md-6 item" v-if="house.parkingInfo.type != ''">
                                        <h4 class="item_key">車位型態</h4>
                                        <p class="item_value">{{ house.parkingInfo.type }}</p>
                                    </li>
                                    <li class="col-md-6 item" v-if="house.parkingInfo.property != ''">
                                        <h4 class="item_key">車位產權</h4>
                                        <p class="item_value">{{ house.parkingInfo.property }}</p>
                                    </li>
                                    <li class="col-md-6 item" v-if="house.parkingInfo.quantity != ''">
                                        <h4 class="item_key">車位數量</h4>
                                        <p class="item_value">{{ house.parkingInfo.quantity }}</p>
                                    </li>
                                    <li class="col-md-6 item" v-if="house.parkingInfo.memo != ''">
                                        <h4 class="item_key">車位備註</h4>
                                        <p class="item_value">{{ house.parkingInfo.memo }}</p>
                                    </li>
                                </ul>
                            </div>
                        </section>
                        <section id="detailAnchor" class="house-info-block"
                                 v-if="house.isLand == 0 && house.showDetail == 1">
                            <h3 class="section_info-title d-flex align-items-center">
                                <i class="icon">
                                    <img src="../assets/images/icon-files.svg"
                                         :alt="siteName + '-物件詳情-' + meta.alt.alt_3_1" class="w-100">
                                </i>
                                物件詳情
                            </h3>
                            <div class="detail_content">
                                <ul class="row">
                                    <li class="col-md-6 item" v-if="house.communityName != ''">
                                        <h4 class="item_key">社區名稱</h4>
                                        <p class="item_value">{{ house.communityName }}</p>
                                    </li>
                                    <li class="col-md-6 item" v-if="house.buildCompany != ''">
                                        <h4 class="item_key">建設公司</h4>
                                        <p class="item_value">{{ house.buildCompany }}</p>
                                    </li>
                                    <li class="col-md-6 item" v-if="house.mainStructure != ''">
                                        <h4 class="item_key">建築結構</h4>
                                        <p class="item_value">{{ house.mainStructure }}</p>
                                    </li>
                                    <li class="col-md-6 item" v-if="house.structureOutlook != ''">
                                        <h4 class="item_key">建築外觀</h4>
                                        <p class="item_value">{{ house.structureOutlook }}</p>
                                    </li>
                                    <li class="col-md-6 item" v-if="house.seated != ''">
                                        <h4 class="item_key">朝向</h4>
                                        <p class="item_value">{{ house.seated }}</p>
                                    </li>
                                    <li class="col-md-6 item" v-if="house.houseHolds != ''">
                                        <h4 class="item_key">每層戶數</h4>
                                        <p class="item_value">{{ house.houseHolds }}</p>
                                    </li>
                                    <li class="col-md-6 item" v-if="house.mrt != ''">
                                        <h4 class="item_key">捷運站</h4>
                                        <p class="item_value">{{ house.mrt }}</p>
                                    </li>
                                    <li class="col-md-6 item" v-if="house.busStation != ''">
                                        <h4 class="item_key">公車站</h4>
                                        <p class="item_value">{{ house.busStation }}</p>
                                    </li>
                                    <li class="col-md-6 item" v-if="house.managementFee > 0">
                                        <h4 class="item_key">管理費</h4>
                                        <p class="item_value">{{ house.managementFee }}({{ house.containParkFee }})</p>
                                    </li>
                                    <li class="col-md-6 item" v-if="house.guard != ''">
                                        <h4 class="item_key">警衛管理</h4>
                                        <p class="item_value">{{ house.guard }}</p>
                                    </li>
                                    <li class="col-md-6 item" v-if="house.smallSchool != ''">
                                        <h4 class="item_key">國小學區</h4>
                                        <p class="item_value">{{ house.smallSchool }}</p>
                                    </li>
                                    <li class="col-md-6 item" v-if="house.mediumSchool != ''">
                                        <h4 class="item_key">國中學區</h4>
                                        <p class="item_value">{{ house.mediumSchool }}</p>
                                    </li>
                                    <li class="col-md-6 item" v-if="house.market != ''">
                                        <h4 class="item_key">市場</h4>
                                        <p class="item_value">{{ house.market }}</p>
                                    </li>
                                    <li class="col-md-6 item" v-if="house.park != ''">
                                        <h4 class="item_key">公園</h4>
                                        <p class="item_value">{{ house.park }}</p>
                                    </li>
                                </ul>
                            </div>
                        </section>
                        <section id="houseAnchor" class="house-info-block">
                            <h3 class="section_info-title d-flex align-items-center" v-if="house.isLand == 0">
                                <i class="icon">
                                    <img src="../assets/images/icon-home.svg"
                                         :alt="siteName + '-房屋介紹-' + meta.alt.alt_3_1" class="w-100">
                                </i>
                                房屋介紹
                            </h3>
                            <h3 class="section_info-title d-flex align-items-center" v-else>
                                <i class="icon">
                                    <img src="../assets/images/icon-home.svg"
                                         :alt="siteName + '-土地介紹-' + meta.alt.alt_3_1" class="w-100">
                                </i>
                                土地介紹
                            </h3>
                            <div class="detail_content">
                                <div class="static-content">
                                    <p v-html="house.houseComment"></p>
                                </div>
                            </div>
                        </section>
                    </div>
                    <div class="col-lg-4 order-1 order-lg-2 info_panel-wrapper">
                        <div class="info_panel"
                             :class="{ static: setStickyBroker == 'static', top: setStickyBroker === 'abs-top', fixed: setStickyBroker === 'fixed', bottom: setStickyBroker === 'abs-bottom' }"
                        >
                            <div class="info-price-group">
                                <div class="row">
                                    <div class="col-12" v-if="house.sell == 2">
                                        <div class="price-group">
                                            <p class="sale_price mb-0">
                                                <span class="nmuber">{{ house.rentPrice }}</span>
                                                <small class="unit">元/月</small>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-6 col-lg-12 col-xl-6" v-else>
                                        <div class="price-group">
                                            <p class="sale_price mb-0">
                                                <span class="nmuber" v-if="house.lowerPrice > 0">{{
                                                        house.lowerPrice
                                                    }}</span>
                                                <span class="nmuber" v-else>{{ house.total }}</span>
                                                <small class="unit">萬</small>
                                            </p>
                                            <del class="cross_price m-0" v-if="house.lowerPrice > 0">{{
                                                    house.total
                                                }}萬
                                            </del>
                                        </div>
                                    </div>
                                    <div class="col-6 col-lg-12 col-xl-6" v-if="house.sell == 1">
                                        <ul class="lists p-0 mb-0 mt-0 mt-lg-4 mt-xl-0">
                                            <li class="list_item">
                                                <p class="mb-0">單價：{{ house.pricePing }}</p>
                                            </li>
                                            <li class="list_item" v-if="house.isLand == 0">
                                                <p class="mb-0">房貸：{{ house.mortgage.monthlyTotal }} 元/月</p>
                                            </li>
                                            <li class="list_item" v-if="house.isLand == 0">
                                                <p class="mb-0"><a href="#!" data-bs-toggle="modal"
                                                                   data-bs-target="#calculateModal"
                                                                   class="link-color calculator"
                                                                   @click="updateCircle(mortgage.percent)">房貸試算 <i
                                                    class="icon">
                                                    <IconCalculator/>
                                                </i></a></p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <hr class="d-none d-lg-block">
                            <div class="d-flex house-mate" v-if="house.isLand == 0">
                                <div class="mate-item" v-if="house.pattern != ''">
                                    <h4>格局</h4>
                                    <p class="mb-0">{{ house.pattern }}</p>
                                </div>
                                <div class="mate-item">
                                    <h4>屋齡</h4>
                                    <p class="mb-0">{{ house.houseAge }}</p>
                                </div>
                                <div class="mate-item">
                                    <h4>坪數</h4>
                                    <p class="mb-0">{{ house.totalPing }}坪</p>
                                </div>
                            </div>
                            <div class="d-flex house-mate" v-else-if="house.isLand == 1">
                                <div class="mate-item">
                                    <h4>型態</h4>
                                    <p class="mb-0">{{ house.houseType }}</p>
                                </div>
                                <div class="mate-item">
                                    <h4>土地坪</h4>
                                    <p class="mb-0">{{ house.landPing }}坪</p>
                                </div>
                            </div>
                            <div class="store-info-group" v-if="showBottomFix == 1">
                                <div class="store-info">
                                    <div class="img">
                                        <div class="img-ratio-location">
                                            <img src="../assets/images/location-1.jpg" alt="">
                                        </div>
                                    </div>
                                    <div class="content">
                                        <h3 class="name company mb-1" v-if="house.officeName != ''">
                                            <div class="text-truncate">{{ house.officeName }}</div>
                                        </h3>
                                        <h3 class="name mb-1">
                                            <div class="text-truncate">{{ house.companyName }}</div>
                                        </h3>
                                    </div>
                                </div>
                                <div class="btn-wrapper">
                                    <div class="mb-0 me-2 mb-lg-2 me-lg-0" v-if="house.officeTel != ''">
                                        <a :href="'tel:' + house.officeTel" class="btn btn-primary w-100 btn-lg"
                                           @click="itemClick(4); track('店頭-電話聯絡', house.officeTel);">
                                            <i class="icon">
                                                <IconPhone/>
                                            </i>
                                            <span class="text">{{ house.officeTel }}</span>
                                        </a>
                                    </div>
                                    <div>
                                        <a :href="house.lineUri" target="_blank" class="btn btn-line w-100 btn-lg"
                                           @click="itemClick(5); track('店頭-Line聯絡', house.lineUri);"
                                           v-if="house.lineUri != ''">
                                            <i class="icon">
                                                <IconLine/>
                                            </i>
                                            <span class="text">用LINE聯絡</span>
                                        </a>
                                        <button type="button" class="btn btn-line w-100 btn-lg" disabled v-else>
                                            <i class="icon">
                                                <IconLine/>
                                            </i>
                                            <span class="text">用LINE聯絡</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 order-3 pt-4 pt-md-5">
                        <section id="mapAnchor" class="house-info-block mb-5">
                            <h3 class="section_info-title d-flex align-items-center">
                                <i class="icon">
                                    <img src="../assets/images/icon-map.svg"
                                         :alt="siteName + '-生活地圖-' + meta.alt.alt_3_1" class="w-100">
                                </i>
                                生活地圖
                            </h3>

                            <div class="row g-2 g-lg-3">
                                <div class="col-12 order-2 order-md-1 mt-0">
                                    <nav class="nav nav-map my-3 mt-md-0">
                                        <ul>
                                            <li :class="'nav-item' + (facilityType == 1 ? ' active' : '')">
                                                <a href="javascript:;" class="nav-link" @click="facilityType = 1">
                                                    <i class="icon">
                                                        <IconFood/>
                                                    </i>
                                                    <span class="text">飲食</span>
                                                </a>
                                            </li>
                                            <li :class="'nav-item' + (facilityType == 2 ? ' active' : '')">
                                                <a href="javascript:;" class="nav-link" @click="facilityType = 2">
                                                    <i class="icon">
                                                        <IconBag/>
                                                    </i>
                                                    <span class="text">購物</span>
                                                </a>
                                            </li>
                                            <li :class="'nav-item' + (facilityType == 3 ? ' active' : '')">
                                                <a href="javascript:;" class="nav-link" @click="facilityType = 3">
                                                    <i class="icon">
                                                        <IconForest/>
                                                    </i>
                                                    <span class="text">休閒</span>
                                                </a>
                                            </li>
                                            <li :class="'nav-item' + (facilityType == 4 ? ' active' : '')">
                                                <a href="javascript:;" class="nav-link" @click="facilityType = 4">
                                                    <i class="icon">
                                                        <IconBus/>
                                                    </i>
                                                    <span class="text">交通</span>
                                                </a>
                                            </li>
                                            <li :class="'nav-item' + (facilityType == 5 ? ' active' : '')">
                                                <a href="javascript:;" class="nav-link" @click="facilityType = 5">
                                                    <i class="icon">
                                                        <IconMoney/>
                                                    </i>
                                                    <span class="text">金融</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                                <div class="col-lg-8 order-1 order-md-2">
                                    <div class="detail_content">
                                        <div id="map" style="height: 350px;"></div>
                                    </div>
                                </div>
                                <div class="col-lg-4 order-3">
                                    <div class="local_pin-lists">
                                        <div class="d-block d-sm-none local_pin-title">
                                            <template v-if="facilityType == 1">
                                                <i class="icon">
                                                    <IconFood/>
                                                </i> 飲食
                                            </template>
                                            <template v-else-if="facilityType == 2">
                                                <i class="icon">
                                                    <IconBag/>
                                                </i> 購物
                                            </template>
                                            <template v-else-if="facilityType == 3">
                                                <i class="icon">
                                                    <IconForest/>
                                                </i> 休閒
                                            </template>
                                            <template v-else-if="facilityType == 4">
                                                <i class="icon">
                                                    <IconBus/>
                                                </i> 交通
                                            </template>
                                            <template v-else-if="facilityType == 5">
                                                <i class="icon">
                                                    <IconMoney/>
                                                </i> 金融
                                            </template>
                                        </div>
                                        <ul>
                                            <li class="item" v-for="facility in house.facilityList[facilityType]">
                                                <a href="javascript:;" class="item-link" @click="goto(facility)">
                                                    <div class="d-none d-sm-block">{{ facility.name }}</div>
                                                    <div class="d-block d-sm-none sm-item">
                                                        <div class="title">{{ facility.name }}</div>
                                                        <div class="distance">距離 {{ facility.distance }} m | 步行時間
                                                            {{ facility.time }} 分鐘
                                                        </div>
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>

            <section class="section-popular detail-popular" v-if="house.houseList.length > 0" id="moreHouse">
                <div class="container">
                    <div class="d-flex">
                        <h2 class="section-title d-flex align-items-center mb-3">
                            <IconStar class="me-2"/>
                            更多好房推薦
                        </h2>
                        <div class="ms-auto popular-arrow">
                            <button class="slick-prev popular-prev customize">prev</button>
                            <button class="slick-next popular-next customize">next</button>
                        </div>
                    </div>

                    <div class="popular-slider">
                        <template v-for="item in house.houseList">
                            <HouseCard
                                :siteName=siteName
                                :about=about
                                :item=item
                            ></HouseCard>
                        </template>
                    </div>
                </div>
            </section>
        </div>

        <div class="modal fade galleryModal" id="galleryModal" tabindex="-1" aria-labelledby="galleryModalLabel"
             aria-hidden="true">
            <div class="modal-dialog modal-fullscreen">
                <div class="modal-content">
                    <div class="modal-header primary-header">
                        <h3 class="modal-title" id="calculateModalLabel" v-if="modalTypeId == 1">格局圖</h3>
                        <h3 class="modal-title" id="calculateModalLabel" v-if="modalTypeId == 2">全景</h3>
                        <h3 class="modal-title" id="calculateModalLabel" v-if="modalTypeId == 3">
                            全部照片({{ house.imgList.length }})</h3>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                            <IconClose/>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-lg-8 mx-auto">
                                <div class="ratio ratio-16x9" v-if="modalType === 'video'">
                                    <iframe width="560" height="315" :src="house.youtubeLink" :title="house.subject"
                                            frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen></iframe>
                                </div>
                                <div class="single-photo" v-if="modalType === 'single'">
                                    <figure>
                                        <img :src="photoSrc" alt="" class="w-100">
                                    </figure>
                                </div>
                                <div class="ratio ratio-16x9" v-if="modalType === 'panoramic'">
                                    <iframe width="560" height="315" :src="vrLink" title="全景" frameborder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowfullscreen></iframe>
                                </div>
                                <div class="gallery-warpper" v-if="modalType === 'gallery'">
                                    <div class="item mb-2" v-if="house.patternImg != ''">
                                        <figure>
                                            <img :src="house.patternImg" alt="格局圖" class="w-100">
                                        </figure>
                                    </div>
                                    <div class="item mb-2" v-for="(img, index) in house.imgList">
                                        <figure>
                                            <img :src="img" :alt="house.subject + '-' + siteName + '-' + (index + 1)"
                                                 class="w-100">
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade calculateModal" id="calculateModal" tabindex="-1" aria-labelledby="calculateModalLabel"
             aria-hidden="true">
            <div class="modal-dialog modal-fullscreen-md-down">
                <div class="modal-content">
                    <div class="modal-header primary-header">
                        <h3 class="modal-title" id="calculateModalLabel">房貸試算</h3>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
                            <IconClose/>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="calctop">
                            <div class="calc-mate">
                                <p class="remember_it">系統會自動幫你記錄下來唷！</p>
                                <h4 class="title">「本息攤還制」試算結果</h4>
                                <ul class="mate_lists">
                                    <li v-if="mortgage.grace > 0">
                                        <div class="key">寬限期內</div>
                                        <div class="value"><b>{{ mortgage.interestGrace }}</b>元/月</div>
                                    </li>
                                    <li v-if="mortgage.grace > 0">
                                        <div class="key">寬限期後</div>
                                        <div class="value"><b>{{ mortgage.monthlyTotal }}</b>元/月</div>
                                    </li>
                                    <li v-if="mortgage.grace == 0">
                                        <div class="key">每月應攤付本息金額</div>
                                        <div class="value"><b>{{ mortgage.monthlyTotal }}</b>元/月</div>
                                    </li>
                                    <li>
                                        <div class="key d-flex align-items-center"><span class="dot dot-2 me-2"></span>
                                            總支付利息
                                        </div>
                                        <div class="value"><b>{{ mortgage.interestTotal }}</b>元</div>
                                    </li>
                                    <li>
                                        <div class="key d-flex align-items-center"><span class="dot dot-1 me-2"></span>貸款金額
                                        </div>
                                        <div class="value"><b>{{ mortgage.lessPriceTotal }}</b>元</div>
                                    </li>
                                </ul>
                            </div>
                            <div class="circle_piechart order-1 order-sm-2">
                                <svg width="200" height="200" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <circle
                                        class="primary-light"
                                        r="90"
                                        cx="100"
                                        cy="100"
                                        fill="transparent"
                                        stroke-dasharray="565.48"
                                        stroke-dashoffset="0"
                                        stroke="#0F3669"
                                        stroke-width="1em"
                                        transform="rotate(-90 100 100)">
                                    </circle>
                                    <circle
                                        id="bar"
                                        class="primary"
                                        r="90"
                                        cx="100"
                                        cy="100"
                                        fill="transparent"
                                        stroke-dasharray="565.48"
                                        stroke-dashoffset="282"
                                        stroke="#3174FF"
                                        stroke-width="1em"
                                        transform="rotate(-90 100 100)"
                                        style="transition: stroke-dashoffset 1s linear;">
                                    </circle>
                                    <text
                                        id="descLabel"
                                        text-anchor="middle"
                                        x="100"
                                        y="90"
                                        font-size="14"
                                        fill="#6c6c6c"
                                        style="font-weight: 400;">總還款金額
                                    </text>
                                    <text
                                        id="percentLabel"
                                        text-anchor="middle"
                                        x="100"
                                        y="120"
                                        font-family="Helvetica Neue"
                                        font-size="22"
                                        style="font-weight: 500; "></text>
                                </svg>
                            </div>
                        </div>
                        <hr class="my-4">
                        <form>
                            <div class="mb-2 mb-sm-3 form-group">
                                <div class="lab">
                                    <label for="" class="col-form-label">物件總價</label>
                                </div>
                                <div class="row flex-grow-1 g-2">
                                    <div class="col-12 col-sm-6">
                                        <div class="input-group">
                                            <input type="text" class="form-control" placeholder="請填寫金額"
                                                   @keyup="calculateMortgage()" v-model="mortgage.total">
                                            <span class="input-group-text">萬</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-2 mb-sm-3 form-group mm_form-group">
                                <div class="lab">
                                    <label for="" class="col-form-label">貸款金額</label>
                                </div>
                                <div class="row flex-grow-1 g-2 cott">
                                    <div class="col-12 col-sm-6">
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" id="mortgageType-1"
                                                   name="mortgageType" value="1" v-model="mortgage.type">
                                            <label class="form-check-label" for="mortgageType-1">
                                                自備款金額
                                            </label>
                                        </div>
                                        <div class="input-group">
                                            <input id="" type="text" class="form-control" placeholder="填寫自備款"
                                                   @keyup="calculateMortgage()" v-model="mortgage.firstPrice">
                                            <span class="input-group-text">萬</span>
                                        </div>
                                        <p class="text-danger2 mb-0">需要再貸 {{ mortgage.lessPrice }} 萬</p>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" id="mortgageType-2"
                                                   name="mortgageType" value="2" v-model="mortgage.type">
                                            <label class="form-check-label" for="mortgageType-2">
                                                貸總價成數
                                            </label>
                                        </div>
                                        <div class="input-group">
                                            <input type="text" class="form-control" placeholder="填寫貸總價成數"
                                                   @keyup="calculateMortgage()" v-model="mortgage.firstPercent">
                                            <span class="input-group-text">％</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-2 mb-sm-3 form-group">
                                <div class="lab">
                                    <label for="" class="col-form-label">貸款利率</label>
                                </div>
                                <div class="row flex-grow-1 g-2">
                                    <div class="col-12 col-sm-6">
                                        <div class="input-group">
                                            <input type="text" class="form-control" placeholder="請填寫貸款利率"
                                                   @keyup="calculateMortgage()" v-model="mortgage.rateYear">
                                            <span class="input-group-text">%</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-2 mb-sm-3 form-group">
                                <div class="lab">
                                    <label for="" class="col-form-label">貸款年限</label>
                                </div>
                                <div class="row flex-grow-1 g-2">
                                    <div class="col-12 col-sm-6">
                                        <div class="input-group">
                                            <input type="text" class="form-control" placeholder="請填寫貸款年限"
                                                   @keyup="calculateMortgage()" v-model="mortgage.year">
                                            <span class="input-group-text">年</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-2 mb-sm-3 form-group">
                                <div class="lab">
                                    <label for="" class="col-form-label">寬限期</label>
                                </div>
                                <div class="row flex-grow-1 g-2">
                                    <div class="col-12 col-sm-6">
                                        <div class="input-group">
                                            <input type="text" class="form-control" placeholder="請填寫寬限期"
                                                   @keyup="calculateMortgage()" v-model="mortgage.grace">
                                            <span class="input-group-text">年</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!--<div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">關閉</button>
                                                <button type="button" class="btn btn-primary" @click="calculateMortgage()">計算</button>
                    </div>-->
                </div>
            </div>
        </div>
        <div class="modal fade sharetoModal" id="sharetoModal" tabindex="-1" aria-labelledby="sharetoModalLabel"
             aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3>分享物件</h3>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-9 mx-auto">
                                <h4 class="text-center">點擊下方方框可自行編輯文案</h4>
                                <textarea id="copyWriting" cols="30" rows="10" class="share-word form-control"
                                          v-if="house.isLand == 1">我發現一個很棒的物件，分享給你

【{{ house.subject }}】
總價：{{ house.total }}萬
地址：{{ house.address }}
類型：{{ house.houseType }}
地坪：{{ house.landPing }}坪

{{ house.officeName }}
{{ house.companyName }}</textarea>
                                <textarea id="copyWriting" cols="30" rows="10" class="share-word form-control" v-else>我發現一個很棒的物件，分享給你

【{{ house.subject }}】
總價：{{ house.total }}萬
地址：{{ house.address }}
格局：{{ house.pattern }}
類型：{{ house.houseType }}
總坪：{{ house.totalPing }}坪
屋齡：{{ house.houseAge }}
地坪：{{ house.landPing }}坪

{{ house.officeName }}
{{ house.companyName }}</textarea>
                                <div class="btn-wrapper row mb-0 mb-md-4">
                                    <div class="col col-md-6 mx-auto">
                                        <a href="#" class="btn btn-outline-default w-100" @click="copyTextarea">複製文案</a>
                                    </div>
                                </div>
                                <div class="btn-wrapper row g-2 mt-0 mt-sm-2">
                                    <div class="col-12 col-sm-6">
                                        <a href="javascript:;" class="btn btn-line w-100"
                                           @click="itemClick(2); openLink(house, 1)">
                                            <div class="icon me-2">
                                                <IconLine/>
                                            </div>
                                            Line 分享
                                        </a>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <a href="javascript:;" class="btn btn-fb w-100"
                                           @click="itemClick(3); openLink( house, 2)">
                                            <div class="icon me-2">
                                                <IconFacebook/>
                                            </div>
                                            Facebook 分享
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import HouseCard from '@/components/HouseCard.vue'
import IconEye from '@/components/icons/IconEye.vue'
import IconPlay from '@/components/icons/IconPlay.vue'
import IconStructure from '@/components/icons/IconStructure.vue'
import IconBus from '@/components/icons/IconBus.vue'
import IconBag from '@/components/icons/IconBag.vue'
import IconMoney from '@/components/icons/IconMoney.vue'
import IconFood from '@/components/icons/IconFood.vue'
import IconForest from '@/components/icons/IconForest.vue'
import IconPhone from '@/components/icons/IconPhone.vue'
import IconLine from '@/components/icons/IconLine.vue'
import IconFacebook from '@/components/icons/IconFacebook.vue'
import IconCalculator from '@/components/icons/IconCalculator.vue'
import IconShare from '@/components/icons/IconShare.vue'
import IconStar from '@/components/icons/IconStar.vue'
import IconClose from '@/components/icons/IconClose.vue'
import {Toast} from 'bootstrap/dist/js/bootstrap.esm.min.js'
import {ScrollSpy} from 'bootstrap/dist/js/bootstrap.esm.min.js'

import 'leaflet/dist/leaflet.css'
import L from 'leaflet'

export default {
    name: 'Detail',
    components: {
        HouseCard,
        IconEye,
        IconPlay,
        IconStructure,
        IconBus,
        IconBag,
        IconMoney,
        IconFood,
        IconForest,
        IconPhone,
        IconCalculator,
        IconLine,
        IconFacebook,
        IconShare,
        IconStar,
        IconClose,
    },
    data() {
        return {
            width: 0,
            showBottomFix: 1,
            modalType: 1,
            about: {},
            siteName: '',
            meta: {
                alt: {}
            },
            house: {
                imgList: [],
                subImg: [],
                facilityList: [],
                houseList: [],
            },
            modalTypeId: '',
            photoSrc: '',
            vrLink: '',
            facilityType: 1,
            mortgage: {
                type: 2,
                firstPrice: '',
                firstPercent: 20,
                rateYear: 1.32,
                year: 30,
                grace: 0,
            },
            percent: 0,

            scroll: 0,
            infoOffsetTop: 0,

            map: '',
            leaflet: {
                zoom: 16,
                center: [22.612961, 120.304167],
                houseLatLng: [22.612961, 120.304167],
                url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                attribution: `© <a href="http://osm.org/copyright">OpenStreetMap</a> contributors`,
                options: {
                    scrollWheelZoom: false,
                },
                icon: {
                    type: [
                        "/map_icon/pin-food.png",
                        "/map_icon/pin-bag.png",
                        "/map_icon/pin-forest.png",
                        "/map_icon/pin-bus.png",
                        "/map_icon/pin-money.png",
                    ],
                    shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
                    iconSize: [35, 45],
                    iconAnchor: [12, 41],
                    popupAnchor: [1, -34],
                    shadowSize: [41, 41]
                },
                markerList: [],
            },

            // marker: {},
            // markerList: [],
            // infoWindowList: [],
            //
            // zoom: 16,
            // center: [22.612961, 120.304167],
            // houseLatLng: [22.612961, 120.304167],
            // url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            // attribution: `© <a href="http://osm.org/copyright">OpenStreetMap</a> contributors`,
            // options: {
            //     scrollWheelZoom: false,
            // },
            // icon: {
            //     type: [
            //         "/map_icon/pin-food.png",
            //         "/map_icon/pin-bag.png",
            //         "/map_icon/pin-forest.png",
            //         "/map_icon/pin-bus.png",
            //         "/map_icon/pin-money.png",
            //     ],
            //     shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
            //     iconSize: [35, 45],
            //     iconAnchor: [12, 41],
            //     popupAnchor: [1, -34],
            //     shadowSize: [41, 41]
            // },
            setStickyBroker: 'static',
        }
    },
    beforeCreate() {
        this.axios.get('/api/houses/detail/' + this.$route.params.uuid)
            .then(response => (
                    this.about = response.data.about,
                        this.house = response.data.house,
                        this.mortgage = this.house.mortgage,
                        this.siteName = response.data.siteName,
                        this.meta = response.data.meta,
                        this.initMap(this.house),
                        document.title = this.house.subject + '-' + this.siteName,
                        $("meta[property='og:title']").attr("content", this.house.subject + '-' + this.siteName),
                        document.getElementsByTagName('meta')["description"].content = this.house.houseComment + this.siteName,
                        $("meta[property='og:description']").attr("content", this.house.houseComment + this.siteName)
                )
            )
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .then(() => {
                // always executed

                // 更多好房推薦
                $(".popular-slider").slick({
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    prevArrow: $('.popular-prev'),
                    nextArrow: $('.popular-next'),
                    responsive: [{
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            // centerMode: true,
                            // centerPadding: '250px',
                            variableWidth: true
                        }
                    }, {
                        breakpoint: 576,
                        settings: {
                            variableWidth: true,
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }]
                });
            });
    },
    beforeRouteUpdate(to) {
        this.axios.get('/api/houses/detail/' + to.params.uuid)
            .then(response => (
                    this.about = response.data.about,
                    this.house = response.data.house,
                    this.mortgage = this.house.mortgage,
                    this.siteName = response.data.siteName,
                    this.meta = response.data.meta,
                    this.map.remove(),
                    this.initMap(this.house),
                    document.title = this.house.subject + '-' + this.siteName,
                    $("meta[property='og:title']").attr("content", this.house.subject + '-' + this.siteName),
                    document.getElementsByTagName('meta')["description"].content = this.house.houseComment + this.siteName,
                    $("meta[property='og:description']").attr("content", this.house.houseComment + this.siteName)
                )
            )
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .then(() => {
                // always executed
                $('.popular-slider').slick('unslick');
                // 更多好房推薦
                $(".popular-slider").slick({
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    prevArrow: $('.popular-prev'),
                    nextArrow: $('.popular-next'),
                    responsive: [{
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            // centerMode: true,
                            // centerPadding: '250px',
                            variableWidth: true
                        }
                    }, {
                        breakpoint: 576,
                        settings: {
                            variableWidth: true,
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }]
                });
            });
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    methods: {
        track: function (type, typeName) {
            if (this.about.ga != '') {
                ga('send', 'event', type, typeName);
            }
            if (this.about.pixel1 != '') {
                fbq('trackSingleCustom', this.about.pixel1, type, {houseType: typeName});
            }
            if (this.about.pixel2 != '') {
                fbq('trackSingleCustom', this.about.pixel2, type, {houseType: typeName});
            }
        },
        galleryModal: function (event) {
            this.show = 1;
            let typ = event.currentTarget.dataset.type
            this.modalType = typ
            switch (typ) {
                case 'single':
                    this.modalTypeId = 1;
                    this.photoSrc = event.currentTarget.dataset.src;
                    break;
                case 'panoramic':
                    this.modalTypeId = 2;
                    // this.vrLink = event.target.href
                    this.vrLink = this.house.vrLink;
                    break;
                case 'video':
                    break;
                case 'gallery':
                    this.modalTypeId = 3;
                    // console.log(event.currentTarget.dataset.index)
                    break;
                default:
                    break;
            }
        },
        calculateMortgage: function () {
            let param = {
                type: this.mortgage.type,
                total: this.mortgage.total,
                firstPrice: this.mortgage.firstPrice,
                firstPercent: this.mortgage.firstPercent,
                rateYear: this.mortgage.rateYear,
                year: this.mortgage.year,
                grace: this.mortgage.grace,
            }
            this.axios.post('/api/houses/calculate_mortgage', param)
                .then(response => (
                        this.mortgage = response.data.mortgage,
                            this.updateCircle(this.mortgage.percent)
                    )
                )
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
                .then(function () {
                    // always executed
                });
        },
        updateCircle: function (percent) {
            var bar = $('#bar');
            var percentLabel = $('#percentLabel');
            var percentOffset = ((100 - percent) / 100) * (Math.PI * (90 * 2));
            percentLabel.text(this.mortgage.all);
            bar.attr('stroke-dashoffset', percentOffset);
        },
        initMap: function (house) {
            this.map = L.map('map').setView([this.house.lat, this.house.lng], this.leaflet.zoom);

            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            }).addTo(this.map);

            for (let type = 1; type <= 5; type++) {
                for (let x = 0; x < this.house.facilityList[type].length; x++) {
                    let item = this.house.facilityList[type][x];
                    let markerItem = L.marker([item.lat, item.lng], {
                        title: item.name, icon: L.icon({
                            iconUrl: this.leaflet.icon.type[type - 1],
                            iconSize: [21, 25],
                        })
                    })
                    markerItem.bindPopup(item.name, {
                        offset: [0, 2],
                        closeButton: false,
                    });
                    markerItem.addTo(this.map);

                    let marker = {
                        type: 0,
                        name: item.name,
                        latLng: [item.lat, item.lng],
                        markerItem: markerItem,
                    };
                    this.leaflet.markerList.push(marker);
                }
            }
            // this.center = [house.lat, house.lng];
            // this.houseLatLng = [house.lat, house.lng];
            // this.mapShow = 1;
            // for (let x = 1; x <= 5; x++) {
            //     for (let y = 0; y < house.facilityList[x].length; y++) {
            //         let item = house.facilityList[x][y];
            //         let marker = {
            //             type: item.type,
            //             name: item.name,
            //             latLng: [item.lat, item.lng],
            //         };
            //         this.markerList.push(marker)
            //     }
            // }
        },
        // markerAdd: function (event, index) {
        //     this.markerList[index].event = event;
        // },
        goto: function (facility) {
            //重新定位地圖位置與標記點位置
            // this.map.setView([facility.lat, facility.lng], this.leaflet.zoom);
            for (let x = 0; x < this.leaflet.markerList.length; x++) {
                if (facility.name == this.leaflet.markerList[x].name) {
                    this.leaflet.markerList[x].markerItem.openPopup();
                    this.map.setView(L.latLng(facility.lat, facility.lng), this.leaflet.zoom);
                    break;
                }
            }
        },
        copyTextarea: function () {
            let copyText = document.getElementById("copyWriting");
            copyText.select();
            copyText.setSelectionRange(0, 99999);
            document.execCommand("copy");

            var myAlert = document.getElementById('toastNotice');//select id of toast
            var bsAlert = new Toast(myAlert);//inizialize it
            bsAlert.show();//show it
            console.log(bsAlert)
            // alert("已複製到剪貼簿");
        },
        handleScroll: function (event) {
            this.scroll = window.scrollY;
        },
        setShow: function () {
            let stickyZone = document.getElementById('stickyZone')
            let width = $(window).width()
            if (width <= 991) {
                this.setStickyBroker = 'static';
            }
            if (width > 991 && stickyZone.getBoundingClientRect().top < 60 && stickyZone.getBoundingClientRect().bottom > 520) {
                this.setStickyBroker = 'fixed';
            } else if (width > 991 && stickyZone.getBoundingClientRect().bottom <= 520) {
                this.setStickyBroker = 'abs-bottom';
            } else {
                this.setStickyBroker = 'abs-top';
            }
        },
        itemClick: function (type) {
            let param = {
                uuid: this.$route.params.uuid,
                type: type,
            }
            this.axios.post('/api/houses/item_click', param)
                .then(response => (
                        console.log(response)
                    )
                )
                .catch(function (error) {
                    // handle error
                    console.log(error);
                })
                .then(function () {
                    // always executed
                });
        },
        openLink: function (house, type) {
            let link;
            if (type == 1) {
                if (this.width <= 767) {
                    link = house.shareLineMobile + document.getElementById("copyWriting").value.replaceAll("\n", "%0A") + '%0a' + house.url;
                } else {
                    link = house.shareLine + '&text=' + document.getElementById("copyWriting").value.replaceAll("\n", "%0A");
                }
            } else {
                link = house.shareFacebook;
            }
            window.open(link, "width=550, height=500");
        },
    },
    mounted() {
        let house = this;
        this.width = $(window).width();
        this.infoOffsetTop = document.getElementById("infoAnchor").offsetTop;

        $(window).scroll(function () {
            if (house.width <= 767) {
                house.showBottomFix = $(window).scrollTop() + $(window).height() < $('.page-footer').position().top;
            }
        });
        // var tooltipTriggerList = [].slice.call(
        //     document.querySelectorAll('[data-bs-toggle="tooltip"]')
        // );
        // tooltipTriggerList.map(function (tooltipTriggerEl) {
        //     return new Tooltip(tooltipTriggerEl);
        // });
        // $('.hero-banner').slick({
        //     dots: true,
        //     infinite: true,
        //     speed: 300
        // });
        //
        // $(".categories-slider").slick({
        //     slidesToShow: 6,
        //     slidesToScroll: 5,
        //     infinite: true,
        //     dots: false,
        //     responsive: [{
        //         breakpoint: 1024,
        //         settings: {
        //             slidesToShow: 4,
        //             slidesToScroll: 3,
        //         }
        //     }, {
        //         breakpoint: 600,
        //         settings: {
        //             arrows: false,
        //             slidesToShow: 3,
        //             slidesToScroll: 3
        //         }
        //     }, {
        //         breakpoint: 480,
        //         settings: {
        //             slidesToShow: 2,
        //             slidesToScroll: 2
        //         }
        //     }]
        // });

        window.setTimeout(function () {
            if (house.width <= 767) {
                $('a[href="/collection"]').addClass('active');
            } else {
                $('a[href="/collection"]').parent().addClass('active');
            }
        }, 500)

        $('.btn-option').focus(function () {
            $(this).blur();
        })

        let scrollSpy = new ScrollSpy(document.body, {
            target: '#stickySpy'
        })
        window.addEventListener("scroll", this.setShow);
    },
    unmounted() {
        window.removeEventListener("scroll", this.setShow);
    }
}
</script>
